<template>
  <div id="app">
    <Tabs></Tabs>
    <div class="content_box">
      <div class="content">
        <!-- 进度条 -->
        <div class="step_box">
          <div class="step_top">
            <img src="../../../../assets/img/dizhi.png" alt="" />
            <div class="breadcrumb_box">
              <p @click="back()">错题本&nbsp;&nbsp;>&nbsp;&nbsp;</p>
              <p>{{ name }}</p>
            </div>
          </div>
          <div class="step_down">
            <div class="step">
              <el-progress
                :percentage="percentage"
                :color="customColor"
                :stroke-width="8"
              ></el-progress>
            </div>
            <div class="step_txt">
              已完成
              <p>{{ page + 1 }}</p>
              道 / 共计
              <p>{{ total_topic }}</p>
              道
            </div>
          </div>
        </div>

        <!-- 选项卡 -->
        <div class="option_box">
          <div
            class="option_content_box"
            v-for="(item, index) in questionList"
            :key="index"
          >
            <!-- 题目卡片 -->
            <div class="option_content" v-if="index === page">
              <!-- 单选/多选/ -->
              <div class="option_name">
                <!-- <p>{{ qtype }}</p> -->
                <p v-if="item.qtype === 1">单选题</p>
                <p v-else-if="item.qtype === 2">配伍题</p>
                <p v-else-if="item.qtype === 3">材料分析选择题</p>
                <p v-else-if="item.qtype === 4">多选题</p>
                <p v-else-if="item.qtype === 5">材料分析题</p>
                <!-- 收藏 -->
                <!-- <div class="collect_box" @click="collect(item)">
                  <img :src="heart" alt="" />
                  {{ text }}
                </div> -->
                <div
                  v-if="my_like == false"
                  class="collect_box"
                  @click="collect(item)"
                >
                  <img src="../../../../assets/img/collect.png" alt="" />
                  收藏本题
                </div>
                <div
                  v-if="my_like == true"
                  class="collect_box no_collect"
                  @click="delcollect(item)"
                >
                  <img src="../../../../assets/img/quxiao.png" alt="" />
                  取消收藏
                </div>
              </div>
              <!-- 题目 / 1纯文字 2富文本 3图片链接-->
              <!-- 单选/多选题/配伍题 -->
              <div class="topic_box" v-show="[1, 2, 4].includes(item.qtype)">
                <div
                  class="question_title"
                  v-show="item.qu_metatype == 1 || item.problem == 1"
                >
                  {{ index + 1 }}. {{ item.qu_content }}
                  {{ item.problem_content }}
                </div>
                <div
                  class="question_title"
                  v-show="item.qu_metatype == 2 || item.problem == 2"
                  style="display: flex"
                >
                  {{ index + 1 }}.
                  <p v-html="item.qu_content"></p>
                  <p v-html="item.problem_content"></p>
                </div>
                <div v-show="item.qu_metatype == 3">
                  {{ index + 1 }}.&nbsp;
                  <img :src="item.qu_content" alt="" />
                </div>
              </div>
              <!-- 配伍题题目 -->
              <div class="topic_box" v-show="item.qtype == 2">
                <div class="question_title">
                  {{ index + 1 }}. 根据下列选项，回答以下问题：
                </div>
                <ul class="option_box">
                  <li v-show="item.an_item_a_content != null">
                    A.&nbsp;
                    <p v-show="item.an_item_a_metatype == 1">
                      {{ item.an_item_a_content }}
                    </p>
                    <img
                      :src="item.an_item_a_content"
                      alt=""
                      v-show="
                        item.an_item_a_metatype == 2 &&
                        item.an_item_a_content != null
                      "
                    />
                  </li>
                  <li v-show="item.an_item_b_content != null">
                    B.&nbsp;
                    <p v-show="item.an_item_b_metatype == 1">
                      {{ item.an_item_b_content }}
                    </p>
                    <img
                      :src="item.an_item_b_content"
                      alt=""
                      v-show="
                        item.an_item_b_metatype == 2 &&
                        item.an_item_b_content != null
                      "
                    />
                  </li>
                  <li v-show="item.an_item_c_content != null">
                    C.&nbsp;
                    <p v-show="item.an_item_c_metatype == 1">
                      {{ item.an_item_c_content }}
                    </p>
                    <img
                      :src="item.an_item_c_content"
                      alt=""
                      v-show="
                        item.an_item_c_metatype == 2 &&
                        item.an_item_c_content != null
                      "
                    />
                  </li>
                  <li v-show="item.an_item_d_metatype != null">
                    D.&nbsp;
                    <p v-show="item.an_item_d_metatype == 1">
                      {{ item.an_item_d_content }}
                    </p>
                    <img
                      :src="item.an_item_d_content"
                      alt=""
                      v-show="item.an_item_d_metatype == 2"
                    />
                  </li>
                  <li v-show="item.an_item_e_content != null">
                    E.&nbsp;
                    <p v-show="item.an_item_e_metatype == 1">
                      {{ item.an_item_e_content }}
                    </p>
                    <img
                      :src="item.an_item_e_content"
                      alt=""
                      v-show="item.an_item_e_metatype == 2"
                    />
                  </li>
                  <li v-show="item.an_item_f_content != null">
                    F.&nbsp;
                    <p v-show="item.an_item_a_metatype == 1">
                      {{ item.an_item_f_content }}
                    </p>
                    <img
                      :src="item.an_item_f_content"
                      alt=""
                      v-show="item.an_item_f_metatype == 2"
                    />
                  </li>
                </ul>
              </div>

              <!-- 材料分析题题目 -->
              <div class="material_problem_box" v-show="item.qtype == 5">
                <!-- 材料 -->
                <div class="stuff_box" style="display: flex">
                  {{ index + 1 }}.
                  <div v-show="item.case_metatype == 1">
                    {{ item.case_content }}
                  </div>
                  <div
                    v-show="item.case_metatype == 2"
                    v-html="item.case_content"
                  ></div>
                  <img
                    v-show="item.case_metatype == 3"
                    :src="item.case_content"
                    alt=""
                  />
                  <!-- <img v-show="item.case_metatype == 1">{{item.case_content}}</img> -->
                </div>
                <!-- 简答题文案 -->
                <div class="brief_answer">
                  <p>简答题</p>
                  <span>（主观题无固定答案，点击底部按钮即可查看答案）</span>
                </div>
                <!-- 问题 -->
                <div class="problem_content_box">
                  <div v-show="item.qtype == 5" style="display: flex">
                    <!-- {{ index + 1 }}. -->
                    <p v-html="item.problem_content"></p>
                  </div>
                </div>
              </div>
              <!-- 问题选项 -->
              <!-- 单选题组 -->
              <div class="radio_box" v-show="[1].includes(item.qtype)">
                <ul>
                  <li
                    v-for="(el, index) in option_list"
                    :key="index"
                    v-show="el.content != null"
                  >
                    <div
                      class="left"
                      @click="clickRadio(el, index, item, $event)"
                      id="isDisabled"
                      :data-id="el.id"
                    >
                      <button
                        :disabled="radio_disabled"
                        :id="el.select"
                        ref="btn"
                      >
                        {{ el.select }}
                      </button>
                      <p v-if="el.type == 1">
                        {{ el.content }}
                      </p>
                      <p
                        v-if="el.type == 2"
                        v-html="item.an_item_a_content"
                      ></p>
                      <img v-show="el.type == 3" :src="el.content" alt="" />
                    </div>
                    <a
                      class="right"
                      :href="el.content"
                      target="_blank"
                      v-show="el.type == 3"
                    >
                      <img src="../../../../assets/img/yulan.png" alt="" />
                      大图预览
                    </a>
                  </li>
                </ul>
              </div>
              <!-- 多选题组 -->
              <div
                class="radio_box check_box"
                v-show="[4].includes(item.qtype)"
              >
                <ul>
                  <li
                    v-for="(el, index) in option_list"
                    :key="index"
                    v-show="el.content != null"
                  >
                    <div
                      class="left"
                      @click="clickCheckbox(el, index, item, $event)"
                      id="isDisabled"
                    >
                      <button
                        :disabled="radio_disabled"
                        :id="el.select"
                        :class="{ check: arr.indexOf(index) > -1 }"
                      >
                        {{ el.select }}
                      </button>
                      <p v-if="el.type == 1">
                        {{ el.content }}
                      </p>
                      <p
                        v-if="el.type == 2"
                        v-html="item.an_item_a_content"
                      ></p>
                      <img v-show="el.type == 3" :src="el.content" alt="" />
                    </div>
                    <a
                      class="right"
                      :href="el.content"
                      target="_blank"
                      v-show="el.type == 3"
                    >
                      <img src="../../../../assets/img/yulan.png" alt="" />
                      大图预览
                    </a>
                  </li>
                </ul>
              </div>
              <!-- 配物题/案例选择题 小题 -->
              <div class="compatibility_box" v-if="[2, 3].includes(item.qtype)">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  background
                  layout="pager"
                  :total="item.qu_list.length"
                  :page-size="1"
                >
                </el-pagination>
                <div class="small_topic_box">
                  <!-- 配伍题/案例选择标题 -->
                  <div class="topic_title">
                    {{ number }}.
                    <p v-if="small_topic.qu_metatype == 1">
                      {{ small_topic.qu_content }}
                    </p>
                    <p
                      v-else-if="small_topic.qu_metatype == 2"
                      v-html="small_topic.qu_content"
                    ></p>
                    <img v-else :src="small_topic.qu_content" alt="" />
                  </div>
                  <div
                    class="radio_box"
                    v-show="[2, 3].includes(item.qtype)"
                    style="margin-bottom: 32px"
                  >
                    <ul>
                      <li
                        v-for="(el, index) in option_list"
                        :key="index"
                        v-show="el.content != null"
                      >
                        <span @click="clickRadio(el, index)">{{
                          el.select
                        }}</span>
                        <p v-if="el.type == 1">
                          {{ el.content }}
                        </p>
                        <p
                          v-if="el.type == 2"
                          v-html="item.an_item_a_content"
                        ></p>
                        <img
                          v-if="el.type == 3"
                          :src="el.an_item_a_content"
                          alt=""
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- 答案解析------------------------------------------------- -->
              <div class="analysis_box" v-if="index === page">
                <!-- 单选/多选/配伍/材料选择 -->
                <div
                  v-if="[1, 2, 3, 4].includes(item.qtype)"
                  v-show="isReveal == true"
                >
                  <div class="top">
                    <div class="left">
                      <span
                        >正确答案: {{ item.answer
                        }}{{ small_topic.answer }}</span
                      >
                      <span
                        v-show="item.answer == user_answer && user_answer != ''"
                      >
                        您的答案:&nbsp;
                        <p style="margin-right: 20px">{{ user_answer }}</p>
                        <img
                          class="dui_icon"
                          src="../../../../assets/img/dui_answer.png"
                          alt=""
                        />
                      </span>
                      <span
                        class="wrong_answer"
                        v-show="item.answer != user_answer || user_answer == ''"
                      >
                        您的答案:&nbsp;
                        <p style="margin-right: 20px">{{ user_answer }}</p>
                        <img src="../../../../assets/img/wrong.png" alt="" />
                      </span>
                    </div>
                    <div class="right" @click="showAnalysis">
                      <!-- <img src="../../../../assets/img/zhankai.png" alt="" />
                    <p>查看解析</p> -->
                      <img :src="look_analysis.icon" alt="" />
                      <p>{{ look_analysis.txt }}</p>
                    </div>
                  </div>
                  <div class="down" v-show="show_analysis === true">
                    <div class="down_content">
                      <!-- 答案解析的元数据类型，1纯文字，2富文本(HTML)，3纯图片 -->
                      <div>答案解析:</div>
                      <div v-if="item.an_detail_metatype == 1">
                        {{ item.an_detail_content }}
                      </div>
                      <div
                        v-html="item.an_detail_content"
                        v-else-if="item.an_detail_metatype == 2"
                      ></div>
                      <img :src="item.an_detail_metatype" alt="" v-else />
                    </div>
                  </div>
                </div>
                <!-- 材料分析题解析答案解析 -->
                <div class="material_analysis_box" v-else>
                  <!-- 答案解析收起状态 -->
                  <div
                    class="put_away_box"
                    id="put_away"
                    @click="showCard"
                    v-if="show_analysis == false"
                  >
                    <p>答案解析</p>
                    <img src="../../../../assets/img/zhankai1.png" alt="" />
                  </div>
                  <!-- 答案解析展开状态 -->
                  <div id="card" v-if="show_analysis == true">
                    <div class="open_box" id="open" @click="showCard1">
                      <p>答案解析</p>
                      <img
                        src="../../../../assets/img/shouqi1 (1).png"
                        alt=""
                      />
                    </div>
                    <div class="card_box">
                      <!-- 1纯文字 2富文本 3图片链接 -->
                      <p v-show="item.an_detail_metatype == 1">
                        {{ item.an_detail_content }}
                      </p>
                      <p
                        v-html="item.an_detail_content"
                        v-show="item.an_detail_metatype == 2"
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div>{{num()}}</div> -->
            <!-- 上一题/下一题 -->
            <div class="cut_topic" v-if="index === page">
              <!-- 上一题 -->
              <button
                class="prev_box"
                :disabled="prev_disabled"
                @click="prev(index, item, $event)"
              >
                <i class="el-icon-arrow-left"></i>上一题
              </button>
              <div
                class="look_answer"
                v-if="[1, 2, 3, 5].includes(item.qtype)"
                @click="look_answer"
              >
                <img src="../../../../assets/img/daan.png" alt="" />
                <p>查看答案</p>
              </div>
              <div class="look_answer" v-else @click="suer_look_answer(item)">
                <img src="../../../../assets/img/daan.png" alt="" />
                <p>确认并查看答案</p>
              </div>
              <button
                class="prev_box"
                :disabled="next_disabled"
                @click="next(index, item)"
              >
                下一题<i
                  class="el-icon-arrow-right"
                  style="margin-left: 30px"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      name: "", // 所在章节
      total_topic: "", // 总题数
      qtype: "一、单选题", // 单选 1/ 多选 4/ 配伍题 2 / 材料分析选择题 3/材料分析题(主观题)/5
      text: "收藏本题",
      heart: require("../../../../assets/img/collect.png"), //收藏心型状态
      my_like: false, // 我的收藏
      collect_data: {}, //收藏数据
      radio_disabled: false, // 单选按钮禁用
      // is_collect: 0, // 0未收藏  1已收藏
      // add_collect: true, // 添加收藏
      // del_collect: false, // 取消收藏
      questionList: [], // 题目列表
      // 存放每一题的id和num
      questionInfos: [],
      page: 0, //当前页
      radio: "", // 选项
      activeNames: "", //["1"]
      boxshow: false, //
      look_analysis: {
        icon: require("../../../../assets/img/shouqi.png"),
        txt: "收起解析",
      },
      show_analysis: true, // 是否显示解析
      nextBtnStatus: false, // 下一题按钮状态
      radio_value: "",
      qu_list: [], // 配伍题小题
      currentPage: 1, // 当前页
      checkList: [], // 多选值
      ageActive: 10, // 单选
      isReveal: false, // 是否展示解析
      option_list: [], // 选项列表
      aa: "",
      number: 1,
      small_topic: {}, //配伍题小题
      user_answer: "", // 用户单选的答案
      option_arr: [], // 存储用户答案
      is_yes: 0,
      prev_disabled: false, //上一题禁用按钮
      next_disabled: false, //下一题禁用按钮
      arr: [], // 多选数组
      strs: [],
      check_box: 10,
      total: [], // 做题总数
      percentage: 0, // 进度条进度
      customColor: "#409eff", // 进度条颜色值
      customColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 },
      ], // 进度条颜色值
      session: [], // 本地存储做题数据
    };
  },
  watch: {
    questionList: {
      deep: true,
      handler() {
        // console.log("数据列表", this.questionList);
      },
    },
    collect_data: {
      deep: true,
      handler() {
        // console.log(11111);
        // console.log(this.collect_data);
        if (this.collect_data.is_collect == 1) {
          this.my_like = true;
        } else {
          this.my_like = false;
        }
        this.$forceUpdate();
      },
    },
  },
  mounted() {
    //数据更新时，你要执行的方式
  },
  computed: {
    // num: function() {
    //   let step = Math.ceil(100 / this.questionList.length).toString()
    //   // console.log(step);
    //   return step;
    // },
  },
  created() {
    this.num();
    // console.log("计算结果",this.number());
    // this.percentage = Math.ceil(100 / this.questionList.length);
    this.getQuestion();
    this.name = this.$route.params.name;
    // console.log("当前page", this.page);
    // this.small_topic = this.small_topic = this.questionList[this.page].qu_list[0];;
    // console.log(this.questionList[this.page].qu_list);
    // console.log("测试",this.questionList);
    if (JSON.parse(sessionStorage.getItem("user"))) {
      this.session = JSON.parse(sessionStorage.getItem("user"));
    } else {
      this.session = [];
    }
  },
  methods: {
    num() {
      // let step = Math.ceil(100 / this.questionList.length).toString();
      let step = Math.round(100 / this.questionList.length);
      // console.log(step);
    },
    //   获取问题列表
    getQuestion() {
      var data = {
        qtype: this.$route.params.qtype,
        page: 1,
        chapter_category: this.$route.params.chapter_id,
      };
      api.ex_wrongnote(data).then((res) => {
        this.$forceUpdate();
        // console.log("试题列表", res);
        if (parseInt(res.data.code) == 1) {
          this.total_topic = res.data.data.list.length;
          this.questionList = res.data.data.list;
          this.percentage = 100 / this.questionList.length;
          // console.log("长度",100 / this.questionList.length);
          if (this.questionList[0].is_collect == 1) {
            this.my_like = 1;
          }
          res.data.data.list.forEach((v) => {
            if (v.qu_list) {
              this.small_topic = v.qu_list[0];
            }
          });
          // this.small_topic = this.questionList[this.page].qu_list[0];
          this.option_list = [
            {
              select: "A",
            },
            {
              select: "B",
            },
            {
              select: "C",
            },
            {
              select: "D",
            },
            {
              select: "E",
            },
            {
              select: "F",
            },
          ];
          let x = res.data.data.list[this.page];
          this.option_list.forEach((v) => {
            // res.data.data.list.forEach((x) => {
            // console.log(v);
            if (v.select == "A") {
              v.content = x.an_item_a_content;
              v.type = x.an_item_a_metatype;
            } else if (v.select == "B") {
              v.content = x.an_item_b_content;
              v.type = x.an_item_b_metatype;
            } else if (v.select == "C") {
              v.content = x.an_item_c_content;
              v.type = x.an_item_c_metatype;
            } else if (v.select == "D") {
              v.content = x.an_item_d_content;
              v.type = x.an_item_d_metatype;
            } else if (v.select == "E") {
              v.content = x.an_item_e_content;
              v.type = x.an_item_e_metatype;
            } else if (v.select == "F") {
              v.content = x.an_item_f_content;
              v.type = x.an_item_f_metatype;
            }
            // });
          });
          // console.log("option_list11111", this.option_list);

          // res.data.data.list.map((el) => {});
        }
      });
    },
    // 返回上一页
    back() {
      this.$router.go(-1);
    },
    // 收藏试题
    collect(el) {
      var data = {
        chapter_category: el.two_chaptercate_id,
        ex_id: el.id,
        qtype: el.qtype,
      };
      api.add_collect(data).then((res) => {
        // console.log("收藏", res);
        if (parseInt(res.data.code) == 1) {
          this.my_like = true;
          this.$message.success({
            duration: 2000,
            message: "收藏成功!",
          });
        } else {
          this.my_like = false;
          this.$message.error({
            duration: 2000,
            message: res.data.msg,
          });
        }
      });
    },
    // 取消收藏
    delcollect(el) {
      var data = {
        chapter_category: el.two_chaptercate_id,
        ex_id: el.id,
        qtype: el.qtype,
      };
      api.del_collect(data).then((res) => {
        // console.log("取消收藏", res);
        if (parseInt(res.data.code) == 1) {
          this.my_like = false;
          this.$message.success({
            duration: 2000,
            message: "取消收藏成功!",
          });
        } else {
          this.my_like = true;
          this.$message.error({
            duration: 2000,
            message: res.data.msg,
          });
        }
      });
    },
    // 点击一级导航切换章节
    choose_major(el) {
      // console.log("章节", el);
      this.one_name = el.name;
      this.dialogVisible = false;
    },

    //单选
    clickRadio(el, index, item, e) {
      // console.log("el------", el);

      // this.$refs.A.style.backgroundColor = "#ff0000";
      this.ageActive = index;
      this.aa = el.select;
      this.user_answer = el.select;
      // console.log(this.aa);
      this.isReveal = true;
      // 存储用户点击的答案
      var obj = {};
      // obj.keys(this.page + 1)
      obj.id = this.page + 1; // 题号
      obj.user_select = el.select; // 用户的选项
      obj.yes_answer = item.answer;
      obj.content = el.content;
      obj.is_click = 1;
      var flag = this.option_arr.find((cur) => cur.id == this.page + 1);
      if (flag) {
        // this.option_arr = this.option_arr.filter((ele) => ele != flag);
      } else {
        this.option_arr.push(obj);
        sessionStorage.setItem("user", JSON.stringify(this.option_arr));
      }
      // console.log(e.currentTarget);
      if (el.select == item.answer) {
        // 用户答对按钮显示绿色
        e.currentTarget.firstElementChild.className = "yes";
      } else {
        // 用户答错按钮显示红色并将正确答案显示绿色
        e.currentTarget.firstElementChild.className = "wrong";
        document.getElementById(item.answer).classList.add("yes");
      }
      // console.log();
      // e.currentTarget.style
      // el.a = this.page + 1;
      // el.sc = 0;
      // el.num = 1;
      // this.arr.push(el);
      // let a = JSON.stringify(this.arr);
      // sessionStorage.setItem("title", a);
      // if (this.isReveal == true) {
      //   // document.getElementById("isDisabled").classList.add("notclick")
      //   // this.radio_disabled = true
      // }
    },
    // 多选
    clickCheckbox(el, index, item, e) {
      let arrindex = this.arr.indexOf(index);
      if (arrindex > -1) {
        this.arr.splice(arrindex, 1);
        this.strs.splice(arrindex, 1);
      } else {
        this.arr.push(index);
        this.strs.push(el.select);
      }
      // console.log("arr", this.arr);
      // console.log("strs", this.strs);
      // // this.arr = []
      // // this.arr.push(el.select);
      // console.log("多选", el.select);
      // console.log("多选数组", this.arr);
      //  // console.log("转换", Array.toString(this.arr));
    },
    // 展开/收起解析
    showAnalysis() {
      this.show_analysis = !this.show_analysis;
      if (this.show_analysis == false) {
        this.look_analysis = {
          icon: require("../../../../assets/img/zhankai.png"),
          txt: "查看解析",
        };
      } else {
        this.look_analysis = {
          icon: require("../../../../assets/img/shouqi.png"),
          txt: "收起解析",
        };
      }
    },
    // 材料分析题解析收起
    showCard() {
      this.show_analysis = true;
    },
    // 材料分析题解析展开
    showCard1() {
      this.show_analysis = false;
    },
    // 查看答案
    look_answer() {
      this.isReveal = true;
    },
    // 多选 查看答案
    suer_look_answer(el) {
      // let arr = ["A","B","C"]

      if (this.strs.length < 2) {
        this.$message.error({
          duration: 1500,
          message: "至少选择两项!",
        });
      } else {
        var answer = this.strs.join(",");
        // console.log(answer);
        this.isReveal = true;
        this.user_answer = answer;
      }
      // console.log("答案",el);
      // console.log("确认",this.strs);
    },
    // 上一题
    prev(id, el, e) {
      // console.log(this.page);
      var data = JSON.parse(sessionStorage.getItem("user"));
      // console.log("page", this.page);

      // var flag = data.find((cur) => cur.id == this.page);
      // var flag1 = data.find((cur) => cur.is_click == 1);
      // -----------------------------------------------------------------------------------------------------------
      //   判断本地是否有答案，有则回显
      //   data.find((cur) => {
      //     if (cur.id == this.page) {
      //       if (cur.user_select == cur.yes_answer) {
      //         document.getElementById(cur.user_select).classList.add("yes");
      //         // document.getElementById("cur.yes_answer").classList.add("wrong");
      //       } else {
      //         document.getElementById(cur.user_select).classList.add("wrong");
      //         document.getElementById(cur.yes_answer).classList.add("yes");
      //       }
      //       // console.log(565656565);
      //       // console.log(document.getElementById(cur.user_select));
      //     }
      //   });

      //   // console.log("本地数据", data);
      // ------------------------------------------------------------------------------------------------
      // var aa = document.getElementById("A");
      // var b = document.getElementById("B");
      // // aa.classList.add("yes")
      // aa.classList.add("yes")
      // b.style.color = "green";
      // console.log(document.getElementById("A"));
      // 进度条
      if (this.page > 0) {
        this.percentage -= 100 / this.questionList.length;
        if (this.percentage < 0) {
          this.percentage = 0;
        }
      }
      this.next_disabled = false;
      el = this.questionList[this.page - 1];
      // console.log("下一题", el);
      this.collect_data = el;
      if (this.page === 0) {
        this.page = 0;
      } else {
        this.page -= 1;
      }
      // 选项列表
      this.option_list.forEach((v) => {
        if (v.select == "A") {
          v.content = el.an_item_a_content;
          v.type = el.an_item_a_metatype;
        } else if (v.select == "B") {
          v.content = el.an_item_b_content;
          v.type = el.an_item_b_metatype;
        } else if (v.select == "C") {
          v.content = el.an_item_c_content;
          v.type = el.an_item_c_metatype;
        } else if (v.select == "D") {
          v.content = el.an_item_d_content;
          v.type = el.an_item_d_metatype;
        } else if (v.select == "E") {
          v.content = el.an_item_e_content;
          v.type = el.an_item_e_metatype;
        } else if (v.select == "F") {
          v.content = el.an_item_f_content;
          v.type = el.an_item_f_metatype;
        }
        // });
      });
      // 判断 当前page = 0时 上一页按钮禁用
      if (this.page == 0) {
        return (this.prev_disabled = true);
      } else {
        return (this.prev_disabled = false);
      }
    },
    // 下一题
    next(index, el) {
      this.prev_disabled = false;
      this.percentage += 100 / this.questionList.length;
      if (this.percentage > 100) {
        this.percentage = 100;
      }
      // console.log("数据", this.total);
      this.isReveal = false;
      // (this.isReveal = false), (this.ageActive = 10);
      el = this.questionList[this.page + 1];
      this.option_list.forEach((v) => {
        // this.questionList.forEach((x) => {
        // console.log(v);
        if (v.select == "A") {
          v.content = el.an_item_a_content;
          v.type = el.an_item_a_metatype;
        } else if (v.select == "B") {
          v.content = el.an_item_b_content;
          v.type = el.an_item_b_metatype;
        } else if (v.select == "C") {
          v.content = el.an_item_c_content;
          v.type = el.an_item_c_metatype;
        } else if (v.select == "D") {
          v.content = el.an_item_d_content;
          v.type = el.an_item_d_metatype;
        } else if (v.select == "E") {
          v.content = el.an_item_e_content;
          v.type = el.an_item_e_metatype;
        } else if (v.select == "F") {
          v.content = el.an_item_f_content;
          v.type = el.an_item_f_metatype;
        }
        // });
      });

      this.collect_data = el;
      if (el.is_collect == 1) {
        this.my_like = true;
      } else {
        this.my_like = false;
      }
      // this.$forceUpdate();
      if (el.qu_list) {
        this.small_topic = el.qu_list[0];
      }
      if (this.page < this.questionList.length - 1) {
        this.page += 1;
        // let question = {
        //   ids: this.radio.slice(2),
        //   nums: this.radio.slice(0, 1),
        // };
        // this.questionInfos[index] = question;
        // console.log("object", this.questionInfos);
        // this.questionInfos.forEach((questionInfo) => {
        //   // console.log('questionInfo:[ids: ' + questionInfo.ids + ', nums: ' + questionInfo.nums + '] ');
        // });
        // 3. index ++；
        index++;
        // 判断 当前页等于最后一题 下一题按钮禁用
        if (this.page == this.questionList.length - 1) {
          return (this.next_disabled = true);
        } else {
          return (this.next_disabled = false);
        }
      }
    },

    handleChange(val) {
      // console.log(val);
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      // this.currentPage = val;
      // console.log(this.questionList[this.page].qu_list[val - 1]);
      // this.questionList[this.page].qu_list[val]
      // console.log(
      //   "1111",
      //   (this.small_topic = this.questionList[this.page].qu_list[0])
      // );
      if (val) {
        this.small_topic = this.questionList[this.page].qu_list[val - 1];
      } else {
      }
      this.number = val;
      // console.log("当前数据", val);
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  // background: #f5f5f5;
  // 内容区
  .content_box {
    width: 100%;
    height: 100vh;
    background: #f5f5f5;
    // padding-bottom: 300px;
    .content {
      width: 1200px;
      margin: auto;
      overflow: hidden;

      // 进度条
      .step_box {
        width: 1120px;
        height: 80px;
        background: #fff;
        margin: 16px 0 10px;
        padding: 0 40px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        border-radius: 12px;
        .step_top {
          display: flex;
          align-items: center;
          // height: 23px;
          // margin: 28px 0 10px;
          img {
            width: 16px;
            height: 18px;
            margin-right: 10px;
          }
          .breadcrumb_box {
            display: flex;
            align-items: center;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 400;
            p:nth-child(1) {
              color: #666;
              font-weight: 400;
              cursor: pointer;
            }
            p:nth-child(2) {
              display: block;
              width: 416px;
              color: #222222;
              font-weight: 500;
            }
          }
        }
        .step_down {
          display: flex;
          align-items: center;
          .step {
            width: 260px;
            margin-right: 30px;
            /deep/.el-progress-bar__outer {
              width: 260px;
            }
            /deep/.el-progress__text {
              display: none;
            }
          }
          .step_txt {
            display: flex;
          }
        }
      }
      // 选项卡
      .option_box {
        width: 100%;
        // height: 1000px;
        // background: pink;
        .option_content_box {
          width: 100%;
          display: flex;
          flex-flow: column;
          align-items: center;
          // padding: 0 40px;
          // 题目
          .option_content {
            width: 1120px;
            min-height: 398px;
            background: #ffffff;
            border-radius: 12px;
            padding: 0 40px;
            // 单选/多选
            .option_name {
              // width: 100%;
              display: flex;
              justify-content: space-between;
              margin: 16px 0 38px;
              p {
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #222222;
              }
              // 收藏
              .collect_box {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                img {
                  width: 16px;
                  height: 14px;
                  margin-right: 4px;
                }
              }
              // 取消收藏
              .no_collect {
                color: #3e7eff;
              }
            }
            // 配伍题问题
            .topic_box {
              .option_box {
                display: flex;
                margin-top: -17px;
                li {
                  display: flex;
                  margin-right: 60px;
                  p {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #222222;
                  }
                }
              }
            }
            // 问题标题
            .question_title {
              font-size: 16px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
              margin-bottom: 25px;
            }
            // 材料分析题问题
            .material_problem_box {
              // 简答题文案
              .brief_answer {
                margin-top: 29px;
                display: flex;
                align-items: center;
                p {
                  font-size: 18px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #222222;
                }
                span {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #666;
                }
              }
              // 问题
              .problem_content_box {
                margin: 37px 0 69px;
                div {
                  p {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #222222;
                  }
                }
              }
            }
            // 单选选项组
            .radio_box {
              ul {
                li {
                  position: relative;
                  width: 100%;
                  display: flex;
                  align-items: center;
                  margin-bottom: 16px;
                  .left {
                    display: flex;
                    align-items: center;
                    width: 973px;
                    max-height: 300px;
                    overflow: hidden;
                    padding-left: 6px;
                    cursor: pointer;
                    button {
                      min-width: 26px;
                      height: 26px;
                      border: 1px solid #989898;
                      border-radius: 50%;
                      font-size: 14px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #222222;
                      text-align: center;
                      // line-height: 26px;
                      margin-right: 14px;
                      background: #fff;
                    }
                    p {
                      font-size: 16px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #222222;
                    }
                    img {
                      max-width: 800px;
                      max-height: 300px;
                      margin-bottom: 9px;
                    }
                    .yes {
                      background: #72b852;
                      color: #ffffff;
                      border: none;
                    }
                    .wrong {
                      background: #ee3d2b;
                      color: #ffffff;
                      border: none;
                    }
                  }
                  .notclick {
                    pointer-events: none;
                  }
                  .left:hover {
                    background: #f7f9ff;
                  }
                  .right {
                    position: absolute;
                    right: 0;
                    bottom: 9px;
                    display: flex;
                    // align-items: center;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #3e7eff;
                    margin-left: 51px;
                    // margin-bottom: 9px;
                    line-height: 16px;
                    img {
                      width: 16px;
                      height: 16px;
                      margin-right: 9px;
                    }
                  }
                }
                li:last-child {
                  margin-bottom: 0;
                }
              }
            }
            // 多选题组
            .check_box {
              ul {
                li {
                  .left {
                    button {
                      border-radius: 4px;
                    }
                    .check {
                      background: #3e7eff;
                      color: #fff;
                      border: none;
                    }
                  }
                }
              }
            }

            // 配伍题/案例选择
            .compatibility_box {
              margin-top: 39px;
              /deep/.el-pagination {
                padding: 0;
                .el-pager {
                  .number {
                    width: 60px;
                    height: 30px;
                    border: 2px solid #78a4ff;
                    border-radius: 0;
                    margin: 0;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #729cff;
                    background: #fff;
                  }
                  .active {
                    border: none;
                    background: #3e7eff;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                  }
                }
              }
              // 小题
              .small_topic_box {
                width: 1102px;
                min-height: 100px;
                border: 2px solid #eeeeee;
                padding-left: 18px;
                .topic_title {
                  display: flex;
                  margin: 30px 0;
                }
              }
            }
            //   答案解析
            .analysis_box {
              width: 1120px;
              margin: 54px auto 40px;
              background: #f5f5f5;
              // 单选/多选/配伍/材料选择
              div {
                .top {
                  height: 60px;
                  background: #f5f5f5;
                  padding: 0 16px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .left {
                    display: flex;
                    span {
                      display: flex;
                      align-items: center;
                      font-size: 16px;
                      font-family: PingFang SC;
                      font-weight: 400;
                      color: #72b852;
                      img {
                        width: 14px;
                        height: 14px;
                      }
                      .dui_icon {
                        width: 17px;
                        height: 12px;
                      }
                    }
                    span:nth-child(1) {
                      margin-right: 45px;
                    }
                    .user_answer {
                      color: #72b852;
                    }
                    .wrong_answer {
                      color: #ee3d2b;
                    }
                  }
                  .right {
                    display: flex;
                    align-items: center;
                    img {
                      width: 14px;
                      height: 14px;
                      margin-right: 6px;
                    }
                    p {
                      font-size: 14px;
                      font-family: PingFang SC;
                      font-weight: 500;
                      color: #777777;
                    }
                  }
                }
                .down {
                  width: 1087px;
                  // min-height: 80px;
                  background: #f5f5f5;
                  border-top: 1px dashed #dddddd;
                  margin: auto;
                  .down_content {
                    display: flex;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    padding: 18px 0;
                    div:nth-child(1) {
                      min-width: 70px;
                      margin-right: 10px;
                    }
                  }
                }
              }
              // 材料分析题解析
              .material_analysis_box {
                position: relative;
                width: 100%;
                background: #fff;
                overflow: hidden;
                // 答案解析展开状态
                .put_away_box {
                  width: 84px;
                  height: 36px;
                  // background: url();
                  background: #ff9302;
                  border-radius: 6px;
                  padding: 0 8px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  // display: none;
                  z-index: -1;
                  p {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                  }
                  img {
                    width: 14px;
                    height: 14px;
                  }
                }
                // 答案解析展开状态
                .open_box {
                  position: absolute;
                  top: 1px;
                  z-index: 9;
                  width: 84px;
                  height: 45px;
                  background: url("../../../../assets/img/shouqi1.png")
                    no-repeat;
                  background-size: 100px 45px;
                  padding: 0 8px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  p {
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                  }
                }
                .card_box {
                  width: 1088px;
                  // min-height: 251px;
                  padding: 0 16px;
                  background: #f5f5f5;
                  margin-top: 6px;
                  overflow: hidden;
                  p {
                    margin: 52px 0 19px;
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #666666;
                  }
                }
              }
            }
          }
          //  上一题/下一题
          .cut_topic {
            position: fixed;
            bottom: 0;
            width: 906px;
            height: 66px;
            background: #ffffff;
            padding: 0 147px;
            box-shadow: 0px -4px 20px 0px rgba(0, 13, 40, 0.03);
            border-radius: 12px 12px 0px 0px;
            z-index: 1;
            // margin: auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin-left: -40px;
            .prev_box {
              display: flex;
              align-items: center;
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
              cursor: pointer;
              background: #fff;
              i {
                margin-right: 30px;
                color: #666;
              }
            }
            .look_answer {
              font-size: 20px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
              display: flex;
              align-items: center;
              img {
                width: 22px;
                height: 26px;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
